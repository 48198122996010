export type Formatter = (value: any) => string;
export type FormatterFactory = (args: ReadonlyMap<string, string>) => Formatter;

export const escapeQuotes = (value: string): string => {
	if (value === null || value === undefined)
		return "";

	return value.toString().replace(/"/g, '\\"');
};

export const htmlSafe = (value: any): string => {
	if (value === null || value === undefined)
		return "";

	return value.toString().replace(/</g, "&lt;").replace(/>/g, "&gt;");
};

const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

const parseDate = (value: any): Date => {
	if (value instanceof Date)
		return value as Date;

	if (typeof value === "string") {
		if (dateRegex.test(value))
			value += "T00:00:00";
		return new Date(value);
	}

	if (typeof value === "number") {
		var abs = Math.abs(value);
		if (abs < 10_000_000_000)	// assume seconds
			return new Date(value * 1_000);
		
		// assume milliseconds
		return new Date(value);
	}

	throw "invalid date";
};

const dateFormatterFactory = (args?: ReadonlyMap<string, string>): Formatter => {
  const options = args?.size ? Object.fromEntries(args.entries()) as Intl.DateTimeFormatOptions : undefined;
	const formatter = Intl.DateTimeFormat(undefined, options);
    return (value: any): string => {
		if (value === undefined)
			return "";
			
		try {
			return formatter.format(parseDate(value))
		}
		catch (error) {
			if (typeof value === "string")
				throw error + ` input:'${value}'`;
			else
				throw error + ` input:${value}`;
		}
	};
};

export const systemFormatters: ReadonlyMap<string, Formatter> = new Map([
  ['__default__', value => value === null || value === undefined ? '' : value.toString()],
	['to-locale-string', value => typeof value === 'number' ? value.toLocaleString() : value],
	['url-safe', value => value ? encodeURIComponent(value.toString()) : ''],
	['quote-safe', escapeQuotes],
  ['html-safe', htmlSafe],
	['date', dateFormatterFactory()],
]);

export const systemFormatterFactories: ReadonlyMap<string, FormatterFactory> = new Map([
   ['date', dateFormatterFactory],
]);
