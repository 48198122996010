const accessor = (path: string, item: object): [object: object, key: string] | undefined => {
	const keys = path.split(".");
	if (keys.length == 1) {
		return [item, keys[0]];
	}
	
	const regex = /^(?<name>.+)\[(?<index>\d+)\]$/; 

	const last = keys.pop()!;
	const object = keys.reduce((object, property) => {
		const match = property.match(regex);
		//console.log('property', property, match);
		if (match) {
			const index = parseInt(match.groups!.index);
			return object?.[match.groups!.name]?.[index];
		}
	
		return object?.[property];
	}, item as any);

	if (typeof object !== "object") {
		return undefined;
	}

	const match = last.match(regex);
	if (match) {
		const index = parseInt(match.groups!.index);
		return [object?.[match.groups!.name], index as unknown as string];
	}

	return [object, last];
};

export const getValue = (path: string, item: object): any => {
	const res = accessor(path, item);
	if (!res) {
		return undefined;
	}

	const [object, key] = res as [Record<string, unknown>, string];
	//console.log('accessor', object, key);

	return object[key];
};

export const setValue = (path: string, item: object, value: unknown): void => {
	const res = accessor(path, item);
	if (res) {
		const [object, key] = res as [Record<string, unknown>, string];

		object[key] = value;
	}
}

export const collectionKey = (collection: string): string => {
	if (collection && typeof collection !== "string")
		throw "collection name is not a string";
		
	return collection ? collection : "__default__";
};

export const replaceUrl = (param: string, values: string | Set<string> | undefined) => {
	const url = new URL(window.location.href);
	const params = new URLSearchParams(url.search);
	
	params.delete(param);

	if (values instanceof Set) {
		// don't bother setting the 'any' case if it's the only selected option since it's the default
		if (values.size !== 1 || !values.has(""))
				values.forEach(value => params.append(param, value));
	} else if (typeof values === "string") {
		params.set(param, values);
	}
	
	url.search = params.toString();

	//console.log("replace", window.location.href, `with '${param}' values`, values, "yielding", url.href);

	history.replaceState(undefined, "", url.href);
};
