import { getValue, setValue } from './util';

export const csv = (item: Record<string, unknown>, ...fields: string[]) => {
  if (item && fields?.length) {
    for (const field of fields) {
      const value = getValue(field, item);
      if (typeof value === 'string') {
        setValue(field, item, value.split(',').map((value) => value.trim()));
      }
    }
  }
};

export const sort = (item: Record<string, unknown>, ...fields: string[]) => {
  if (item && fields?.length) {
    for (const field of fields) {
      const value = getValue(field, item);
      if (Array.isArray(value)) {
        value.sort();
      }
    }
  }
};