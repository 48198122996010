import { FilterTemplate, findFilterTemplates, collectFilterOptions, renderFilterTemplates, applyFilters } from "./filters";
import { Formatter, FormatterFactory, systemFormatterFactories, systemFormatters } from "./formatters";
import { Processor, ProcessorFactory, systemProcessorFactories, systemProcessors } from "./processors";
import { Sorter, findSorters } from './sort';
import { DataTemplate, TemplateType, findDataTemplates, inject, renderDataTemplate } from "./templates";
import { getValue, replaceUrl } from './util';
export { csv, sort } from "./preprocessor-utils";

export type Preprocessor = (value: object) => void;
export type Endpoint = { url: string, preprocessor?: Preprocessor };

export type Config = {
    endpoints: ReadonlyMap<string, Endpoint>;
    formatters: ReadonlyMap<string, Formatter>;
    formatterFactories: ReadonlyMap<string, FormatterFactory>;
    processors: ReadonlyMap<string, Processor>;
    processorFactories: ReadonlyMap<string, ProcessorFactory>;
}

export interface CollectionState {
    preprocessor?: Preprocessor,
    dataTemplate: DataTemplate,
    filterTemplates: ReadonlyMap<string, FilterTemplate>,
    items: object[],
    error?: boolean,
    sorter?: Sorter,
    filterOptions: ReadonlyMap<string, ReadonlySet<string>>,
    filters: Map<string, Set<string>>,  // active filters
    filtered: object[],
    formatters: Map<string, Formatter>,
    formatterFactories: ReadonlyMap<string, FormatterFactory>,
    processors: Map<string, Processor>,
    processorFactories: ReadonlyMap<string, ProcessorFactory>,
}

const endpointSanitizer = (arg: unknown): string => {

    if (typeof arg !== "string")
        throw "endpoint definition must be a URL string";

    const url = (arg as string).trim();

    if (!url)
        throw "endpoint URL was empty, blank, or not specified";

    try {
        new URL(url);
    }
    catch {
        throw "endpoint URL is not a valid URL";
    }

    return url;
};

/*
 * Clean up 
 */
const configSanitizer = (arg: unknown, preprocessor?: Preprocessor): Config => {
    if (arg === undefined || arg == null)
        throw "no url or config specified";

    if (typeof arg === "string") {
        return {
            endpoints: new Map([['__default__', { url: endpointSanitizer(arg), preprocessor }]]),
            formatters: systemFormatters,
            formatterFactories: systemFormatterFactories,
            processors: systemProcessors,
            processorFactories: systemProcessorFactories,
        };
    }
    else if (typeof arg === "object") {
        const config = arg as Config;
        if (typeof config.endpoints === "string" || typeof config.endpoints === "object") {
            const endpoints: Map<string, Endpoint> = new Map;
            if (typeof config.endpoints === "string") {
                endpoints.set('__default__', { url: endpointSanitizer(config.endpoints), preprocessor });
            } else {
                for (const [name, endpoint] of (config.endpoints instanceof Map ? config.endpoints : Object.entries(config.endpoints))) {
                    if (!name || typeof name !== "string")
                        throw `invalid endpoint name: ${name}`;

                    if (typeof endpoint === 'string') {
                        endpoints.set(name.trim(), { url: endpointSanitizer(endpoint), preprocessor });
                    } else if (typeof endpoint === 'object') {
                        endpoints.set(name.trim(), { url: endpointSanitizer(endpoint.url), preprocessor: endpoint.preprocessor ?? preprocessor });
                    }
                }
            }

            const formatters = new Map(systemFormatters);
            if (config.formatters) {
                if (typeof config.formatters === "object") {
                    for (const [name, func] of (config.formatters instanceof Map ? config.formatters : Object.entries(config.formatters))) {
                        if (!name || typeof name !== "string")
                            throw `invalid formatter name: ${name}`;
                        
                        if (typeof func !== "function")
                            throw `formatter '${name}' is not a function`;
                        
                        formatters.set(name, func as Formatter);
                    }
                }
                else
                    throw "formatters is not an object or Map";
            }

            const formatterFactories = new Map(systemFormatterFactories);
            if (config.formatterFactories) {
                if (typeof config.formatterFactories === "object") {
                    for (const [name, func] of (config.formatterFactories instanceof Map ? config.formatterFactories : Object.entries(config.formatterFactories))) {
                        if (!name || typeof name !== "string")
                            throw `invalid formatterFactory name: ${name}`;
                        
                        if (typeof func !== "function")
                            throw `formatterFactory '${name}' is not a function`;
                        
                        formatterFactories.set(name, func as FormatterFactory);
                    }
                }
                else
                    throw "formatterFactories is not an object or Map";
            }

            const processors = new Map(systemProcessors);
            if (config.processors) {
                if (typeof config.processors === "object") {
                    for (const [name, func] of (config.processors instanceof Map ? config.processors : Object.entries(config.processors))) {
                        if (!name || typeof name !== "string")
                            throw `invalid processor name: ${name}`;
                        
                        if (typeof func !== "function")
                            throw `processor '${name}' is not a function`;
                        
                            processors.set(name, func as Processor);
                    }
                }
                else
                    throw "processors is not an object or Map";
            }

            const processorFactories = new Map(systemProcessorFactories);
            if (config.processorFactories) {
                if (typeof config.processorFactories === "object") {
                    for (const [name, func] of (config.processorFactories instanceof Map ? config.processorFactories : Object.entries(config.processorFactories))) {
                        if (!name || typeof name !== "string")
                            throw `invalid processorFactory name: ${name}`;
                        
                        if (typeof func !== "function")
                            throw `processorFactory '${name}' is not a function`;
                        
                        processorFactories.set(name, func as ProcessorFactory);
                    }
                }
                else
                    throw "processorFactories is not an object or Map";
            }

            return {
                endpoints: endpoints,
                formatters: formatters,
                formatterFactories: formatterFactories,
                processors: processors,
                processorFactories: processorFactories,
            };
        }
        else
            throw "arg is not valid: expected a Config object";
    }
    else
        throw "arg is not a valid type";
};

export const render = async (urlOrConfig: string | Config, preprocessor?: Preprocessor) => {
    const config = configSanitizer(urlOrConfig, preprocessor);
    console.log(config);

    const dataTemplates = findDataTemplates(config.endpoints);
    const sorters = findSorters();
    const filterTemplates = findFilterTemplates();

    const collections = new Set<string>();
    if (config.endpoints)
        for (const collection of config.endpoints.keys()) collections.add(collection);
    for (const collection of dataTemplates.keys()) collections.add(collection);
    for (const collection of filterTemplates.keys()) collections.add(collection);

    const promises = [];
    for (const collection of collections) {
        const dataTemplate = dataTemplates.get(collection);
        if (!dataTemplate) {
            console.error(`No data template for collection ${collection}`);
            continue;
        }

        const filterTemplate = filterTemplates.get(collection);

        const state: CollectionState = {
            preprocessor: config.endpoints.get(collection)?.preprocessor,
            dataTemplate: dataTemplate,
            items: [],
            sorter: sorters.get(collection),
            filterTemplates: filterTemplate ?? new Map,
            filterOptions: new Map,
            filters: new Map,
            filtered: [],
            formatters: new Map(config.formatters ?? []),
            formatterFactories: new Map(config.formatterFactories ?? []),
            processors: new Map(config.processors ?? []),
            processorFactories: new Map(config.processorFactories ?? []),
        };

        renderDataTemplate("loading", state);
        const url = formatUrl(dataTemplate.url, state);
        const promise = fetchPage(url, state);
        promises.push(promise);
    }

    await Promise.all(promises);
};

export let _onrender: (type: TemplateType) => void;
export const onrender = (fn: (type: TemplateType) => void) => {
    _onrender = fn;
};

const fetchPage = async (url: string, state: CollectionState, page?: string): Promise<void> => {
    await fetch(addParam(url, state.dataTemplate.nextPageParam, page))
    .then(response => {
        if (response.status / 100 != 2)
            throw response.status;
        return response.json();
    })
    .then(async apiResponse => {
        const value = state.dataTemplate.prop ? apiResponse[state.dataTemplate.prop] : apiResponse;
       
        //console.log("items before:", state.items);
        if (Array.isArray(value))
            value.forEach(item => state.items.push(item));
        else
            state.items.push(value);
        //console.log("items after:", state.items);

        let last = true;
        if (state.dataTemplate.nextPageProp && state.dataTemplate.nextPageParam) {
            const nextPage = apiResponse[state.dataTemplate.nextPageProp];
            if (nextPage) {
                last = false;
                await fetchPage(url, state, nextPage);
            }
        }

        if (last) {
            if (state.preprocessor) {
                for (let i = 0; i < state.items.length; i++) {
                    const item = state.items[i];
                    state.preprocessor(item);
                }
            }

            if (state.sorter) {
                const sort = (items: object[], prop: string, dir: number) => {
                    // console.log('sorting', prop, dir);
                    items.sort((a, b) => {
                        const aVal = getValue(prop, a);
                        const bVal = getValue(prop, b);
                        if (aVal === bVal) return 0;
                        if (aVal === undefined) return -dir;
                        if (bVal === undefined) return dir;

                        if (typeof aVal === "number" && typeof bVal === "number")
                            return (aVal - bVal) * dir;

                        if (typeof aVal === "string" && typeof bVal === "string")
                            return aVal.localeCompare(bVal) * dir;

                        if (typeof aVal === "boolean" && typeof bVal === "boolean")
                            return (aVal ? 1 : 0) - (bVal ? 1 : 0) * dir;

                        if (typeof aVal === "object" && typeof bVal === "object")
                            return 0;

                        return 0;
                    });
                };

                const search = new URLSearchParams(location.search.substring(1));
                const sortBy = search.get(state.sorter.param) ?? state.sorter.default;
                if (sortBy) {
                    const dir = sortBy.startsWith('-') ? -1 : 1;
                    const value = sortBy.startsWith('-') ? sortBy.substring(1) : sortBy;
                    const prop = state.sorter.options.get(value);
                    //console.log('sorting by', sortBy, value, prop, state.sorter);
                    if (prop) {
                        sort(state.items, prop, dir);
                    }
                }

                state.sorter.onChange = (option: string, prop: string) => {
                    if (state.sorter) {
                        const search = new URLSearchParams(location.search.substring(1));
                        const current = search.get(state.sorter.param);
                        if (current !== option) {
                            if (option === state.sorter.default)
                                search.delete(state.sorter.param);
                            else
                                search.set(state.sorter.param, option);
                            
                            replaceUrl(state.sorter.param, option);

                            sort(state.filtered, prop, option.startsWith('-') ? -1 : 1);

                            // console.log('rendering');
                            renderDataTemplate("item", state);
                        }
                    }
                }
            }

            state.filterOptions = collectFilterOptions(state);
            //console.log("filter options", state.filterOptions);
            for (const prop of state.filterOptions.keys())
                state.filters.set(prop, new Set);

            renderFilterTemplates(state);

            if (!state.items)
                renderDataTemplate("empty", state);
            else {
                state.filtered = [...state.items];
                applyFilters(state);
                renderDataTemplate("item", state);
            }
        }
    })
    .catch(error => {
        state.items = [];
        state.error = true;
        if (error === 404) {
            console.error("failed to fetch item(s) from ", url, "not found", error);
            renderDataTemplate("not-found", state);
        }
        else {
            console.error("failed to fetch item(s) from ", url, "due to", error);
            renderDataTemplate("error", state);
        }
    });
};

const addParam = (url: string, key?: string, value?: string): string => {
    //console.log("url", url, "key", key, "value", value);
    if (key && value)
        return url + (url.indexOf("?") == -1 ? "?" : "&") + encodeURIComponent(key) + "=" + encodeURIComponent(value);

    return url;
};

const formatUrl = (url: string, state: CollectionState): string => {
    const params = new URLSearchParams(location.search.substring(1));
    const data = Object.fromEntries(params.entries());
    return inject(url, data, state, encodeURIComponent);
}