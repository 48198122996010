export type Processor = (value: any, element: HTMLElement) => void;
export type ProcessorFactory = (args: Map<string, string>) => Processor;

let lastTwitterEmbed = Promise.resolve();

declare var twttr:  { widgets: { createTweet: Function } };
const twitterUrlRegex = /^https:\/\/(www\.)?twitter\.com\/.*\/([0-9]+)$/;
const twitterIdRegex = /^[0-9]+$/;
const generateTwitterEmbed = (value: any, element: HTMLElement) => {
	if (typeof value == "string") {
		if (!value.match(twitterIdRegex)) {
			const matches = value.match(twitterUrlRegex);
			if (!matches) {
				console.warn("Can't find twitter id in", value);
				return;
			}
			value = matches[2];
		}

		//lastTwitterEmbed = lastTwitterEmbed.then(() => twttr.widgets.createTweet(value, element));
		twttr.widgets.createTweet(value, element)
	}
	else
		console.warn("Can't find twitter id in", value);
}

const applyToBackgroundImage = (value: any, element: HTMLElement) => {
	element.style.backgroundImage = `url(${value})`;
};

const generateYouTubeEmbed = (value: any, element: HTMLElement) => {
	if (typeof value == "string") {
		const videoId = value.match(/youtube\.com\/watch\?v=([^&]+)/)?.[1];

		if (videoId) {
			const iframe = document.createElement("iframe");
			iframe.src = `https://www.youtube.com/embed/${videoId}`;
			iframe.setAttribute("width", "100%");
			iframe.setAttribute("height", "100%");
			iframe.setAttribute("frameborder", "0");
			iframe.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");
			iframe.setAttribute("allowfullscreen", "");
			element.appendChild(iframe);
			return;
		}
	}

	console.warn("Can't find youtube id in", value);
};

export const systemProcessors: ReadonlyMap<string, Processor> = new Map([
	['embed-tweet', generateTwitterEmbed],
	['embed-youtube', generateYouTubeEmbed],
	['background-image', applyToBackgroundImage],
]);

export const systemProcessorFactories: ReadonlyMap<string, ProcessorFactory> = new Map([]);

