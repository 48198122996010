import { getValue, collectionKey } from "./util";
import { CollectionState, Config, _onrender } from ".";
import { escapeQuotes, Formatter, htmlSafe } from "./formatters";
import { Processor } from "./processors";

export interface DataTemplate {
	url: string,
	prop?: string,
	sortBy?: string,
	nextPageProp?: string,
	nextPageParam?: string,
    container: HTMLElement,
	templates: Map<TemplateType, HTMLElement>,
}

const templateTypes = [ "item", "empty", "loading", "not-found", "error"] as const;
export type TemplateType = typeof templateTypes[number];

export const findDataTemplates = (endpoints: Readonly<Config['endpoints']>): Map<string, DataTemplate> => {
	const collectionTemplates: Map<string, DataTemplate> = new Map;

	for (const container of document.querySelectorAll<HTMLElement>("[data-collection]")) {
		const collectionName = container.getAttribute("data-collection");
		const collection = collectionKey(collectionName!);	// we know it's not null

		const url = container.getAttribute("data-url")?.trim() ?? endpoints.get(collection)?.url;

		if (!url)
			throw `No endpoint URL for collection ${collection}`;
		
		try {
			new URL(url);
		}
		catch {
			throw `Invalid URL for collection ${collection}: ${url}`;
		}

		const prop = container.getAttribute("data-prop")?.trim();
		const sortBy = container.getAttribute("data-sort-by")?.trim();
		const nextPageProp = container.getAttribute("data-next-page-prop")?.trim();
		const nextPageParam = container.getAttribute("data-next-page-param")?.trim();

		const templates = new Map<TemplateType, HTMLElement>();

		for (const type of templateTypes) {
			const template = container.querySelector(`[data-template="${type}"]`);
			if (template) {
				templates.set(type, template as HTMLElement);
				template.removeAttribute("data-template");
			}
		}

		if (!templates.has("item"))
			throw `No item template for collection ${collection}`;

		container.removeAttribute("data-collection");
		container.removeAttribute("data-url");
		container.removeAttribute("data-prop");
		container.removeAttribute("data-sort-by");
		container.removeAttribute("data-next-page-prop");
		container.removeAttribute("data-next-page-param");

		const dataTemplate: DataTemplate = {
			url: url,
			prop: prop,
			sortBy: sortBy,
			nextPageProp: nextPageProp,
			nextPageParam: nextPageParam,
			container: container as HTMLElement,
			templates: templates,
		};

		container.replaceChildren();	// remove all children

		collectionTemplates.set(collection, dataTemplate);
	}
	return collectionTemplates;
}

function getFormatter(name: string, state: CollectionState): Formatter {
	if (name) {
		let formatter = state.formatters.get(name);
		if (formatter)
			return formatter;
		
		const spec = name;
		const semi = spec.indexOf(";");
		const args = new Map<string, string>();
		if (semi != -1) {
			name = spec.substring(0, semi).trim();
			for (const pair of spec.substring(semi + 1).split(",")) {
				const eq = pair.indexOf("=");
				if (eq != -1) {
					const key = pair.substring(0, eq).trim();
					const value = pair.substring(eq + 1).trim();
					args.set(key, value);
				}
				else
					args.set(pair.trim(), "");
			}
		}

		const factory = state.formatterFactories.get(name);
		if (factory) {
			formatter = factory(args);

			state.formatters.set(spec, formatter);

			return formatter;
		}
	}

	return state.formatters.get("__default__") as Formatter;	// will never be undefined
}

function getProcessor(name: string, state: CollectionState): Processor | undefined {
	if (name) {
		let processor = state.processors.get(name);
		if (processor)
			return processor;
		
		const spec = name;
		const semi = spec.indexOf(";");
		const args = new Map<string, string>();
		if (semi != -1) {
			name = spec.substring(0, semi).trim();
			for (const pair of spec.substring(semi + 1).split(",")) {
				const eq = pair.indexOf("=");
				if (eq != -1) {
					const key = pair.substring(0, eq).trim();
					const value = pair.substring(eq + 1).trim();
					args.set(key, value);
				}
				else
					args.set(pair.trim(), "");
			}
		}

		const factory = state.processorFactories.get(name);
		if (factory) {
			processor = factory(args);

			state.processors.set(spec, processor);

			return processor;
		}
	}
}

export const inject = (pattern: string, data: any, state: CollectionState, cleaner: (text:string) => string): string => {
	if (!pattern)
		return pattern;

	let text = "";
	for (let i = 0; i < pattern.length;) {
		const start = pattern.indexOf("{{", i);
		if (start == -1) {
			text += pattern.substring(i);
			break;
		}
		const end = pattern.indexOf("}}", start + 2);
		if (end == -1) {
			text += pattern.substring(i);
			break;
		}
		
		const spec = pattern.substring(start + 2, end).trim();
		const semi = spec.indexOf(";");
		const prop = semi != -1 ? spec.substring(0, semi).trim() : spec;
		const formatter = getFormatter(semi != -1 ? spec.substring(semi + 1).trim() : "__default__", state);

		text += pattern.substring(i, start);

		if (typeof data === "object") {
			text += cleaner(formatter(getValue(prop, data)));
		}
		else if (data) {
			text += cleaner(data.toString());
		}
		i = end + 2;
	}

	return text;
};

const hrefWebflowHackRegex = /https?:\/\/(https?:\/\/.*)/;

const injectAttributes = (element: HTMLElement, data: object, state: CollectionState) => {
	for (const attribute of element.attributes) {
		const pattern = attribute.value;

		if (pattern.indexOf("{{") == -1)
			continue;
		
		const value = inject(pattern, data, state, escapeQuotes);
		const urlHack = value.match(hrefWebflowHackRegex);
		if (urlHack)
			attribute.value = urlHack[1];
		else
			attribute.value = value;
	}
};

export const populateElement = (element: HTMLElement, data: any, state: CollectionState) => {
	
	const hideProps = element.getAttribute("data-hide-if-empty")?.split('|').map(prop => prop?.trim());
	if (hideProps) {
		for (let i = 0; i < hideProps.length; i++) {
			const prop = hideProps[i];
			if (prop) {
				const value = getValue(prop, data);
				if (!value) {
					element.parentElement?.removeChild(element);
					return;	// suppress this element
				}
			}
		}
	}
	element.removeAttribute("data-hide-if-empty");
	
	const href = element.getAttribute("data-href");
	if (href) {
		const value = typeof data === "object" ? getValue(href, data) : data;
		element.setAttribute("href", value);
	}
	element.removeAttribute("data-href");

	const prop = element.getAttribute("data-prop");
	element.removeAttribute("data-prop");

	const formatterName = element.getAttribute("data-formatter") as string;
	element.removeAttribute("data-formatter");

	const processorName = element.getAttribute("data-processor") as string;
	element.removeAttribute("data-processor");

	const formatter = getFormatter(formatterName, state);
	const processor = getProcessor(processorName, state);

	const value = prop && typeof data === "object" ? getValue(prop, data) : data;

	if (processor) {
		processor(value, element);

		populateChildren(element, data, state);
	}
	else if (Array.isArray(value)) {
		const template = element.firstElementChild;
		if (template) {
			element.removeChild(template);
			for (const item of value) {
				const card = template.cloneNode(true) as HTMLElement;
				populateElement(card, item, state);
				element.appendChild(card);
			}
		} else {
			// output as comma separated if textFormatter
			element.innerHTML = "";
			for (const arrayElement of value) {
				const formatted = arrayElement ? formatter(arrayElement) : "";
				if (element.textContent)
					element.textContent += ", ";
				element.textContent += formatted;
			}
		}
	}
	else {
		injectAttributes(element, value, state);

		if (prop) {
			const formatted = formatter(value?.toString());
			if (element instanceof HTMLImageElement) {
				element.src = formatted;
			}
			else
				element.textContent = value ? htmlSafe(formatted) : "";
		}
		else {
			populateChildren(element, value, state);
		}
	}
};

const populateChildren = (element: HTMLElement, data: any, state: CollectionState) => {
	for (const node of [...element.childNodes]) {
		switch (node.nodeType) {
			case Node.COMMENT_NODE:
			case Node.TEXT_NODE: {
				const text = inject(node.nodeValue as string, data, state, htmlSafe);
				node.nodeValue = text;
				break;
			}
			case Node.ELEMENT_NODE: {
				populateElement(node as HTMLElement, data, state);
				break;
			}
		}
	}
}

const sorter = (prop: string): ((lhs: any, rhs: any) => number) => {
	return (lhs: any, rhs: any): number => {
		const lhsProp = getValue(prop, lhs)?.toString().toUpperCase();
		const rhsProp = getValue(prop, rhs)?.toString().toUpperCase();

		if (!lhsProp && !rhsProp)
			return 0;
		
		if (!lhsProp && rhsProp)
			return -1;

		if (lhsProp && !rhsProp)
			return 1;
		
		if (lhsProp < rhsProp)
			return -1;
		
		if (lhsProp > rhsProp)
			return 1;
		
		return 0;
	};
}

export const renderDataTemplate = (type: TemplateType, state: CollectionState) => {
	state.dataTemplate.container.replaceChildren();
	const template = state.dataTemplate.templates.get(type);
	if (template) {
		if (type === "item") {
			if (state.filtered) {

				const list = state.dataTemplate.sortBy ? [...state.filtered].sort(sorter(state.dataTemplate.sortBy)) : state.filtered;
				for (const item of list) {
					const clone = template.cloneNode(true) as HTMLElement;
					populateElement(clone, item, state);
					state.dataTemplate.container.appendChild(clone);
				}
			}
		}
		else {
			const clone = template.cloneNode(true);
			state.dataTemplate.container.appendChild(clone);
		}

		emitOnRender(type);
	}
};

const emitOnRender = (type: TemplateType) => {
    if (_onrender) {
        try {
            _onrender(type);
        }
        catch (error) {            
        }
    }
}
