import { getValue, replaceUrl } from "./util";
import { CollectionState } from ".";
import { populateElement, renderDataTemplate } from "./templates";

export interface FilterTemplate {
    filterContainer: HTMLElement,
    anyTemplate: HTMLElement,
    optionTemplate: HTMLElement,
    urlParam?: string,
}

export const findFilterTemplates = (): Map<string, Map<string, FilterTemplate>> => {
    const filters: Map<string, Map<string, FilterTemplate>> = new Map;
    for (const elem of document.querySelectorAll("[filter-collection]")) {
        const collection = elem.getAttribute("filter-collection")?.trim();
        const prop = elem.getAttribute("filter-prop")?.trim();
        const urlParam = elem.getAttribute("filter-url-param")?.trim();

        const container = elem as HTMLElement;
        if (prop) {
            const anyTemplate = container.querySelector('[filter-template="any"]') as HTMLElement;
            const optionTemplate = container.querySelector('[filter-template="option"]') as HTMLElement;

            if (!anyTemplate) {
                console.error("Missing 'any' template for filter collection", collection, "prop", prop);
                continue;
            }

            if (!optionTemplate) {
                console.error("Missing 'option' template for filter collection", collection, "prop", prop);
                continue;
            }

            container.replaceChildren();    // remove all children
            container.removeAttribute("filter-collection");
            container.removeAttribute("filter-prop");

            anyTemplate.removeAttribute("filter-template");
            optionTemplate.removeAttribute("filter-template");

            const filter: FilterTemplate = {
                filterContainer: container,
                anyTemplate: anyTemplate,
                optionTemplate: optionTemplate,
                urlParam: urlParam,
            };

            const collectionFilters = filters.get(collection ? collection : "__default__") ?? new Map<string, FilterTemplate>();
            if (collectionFilters.size == 0)
                filters.set(collection ? collection : "__default__", collectionFilters);
            collectionFilters.set(prop, filter);
        }
        else
            console.error("Filter property not specified", elem);
    }

    return filters;
};

export const collectFilterOptions = (state: CollectionState): Map<string, Set<string>> => {
    const filters: Map<string, Set<string>> = new Map;

    if (state.items)
        for (const prop of state.filterTemplates.keys()) {
            const options = new Set<string>();
            for (const item of state.items) {
                const value = getValue(prop, item);
                if (value) {
                    if (Array.isArray(value))
                        value.forEach(item => options.add(item));
                    else
                        options.add(value);
                }
            }
            filters.set(prop, options);
        }

    return filters;
};

type FilterState = {
    label?: string;
    elem?: HTMLElement;
}

export const renderFilterTemplates = (state: CollectionState) => {
    for (const [prop, filter] of state.filterTemplates) {
        filter.filterContainer.replaceChildren();

//        console.log('filtering', prop, filter);

        const options = state.filterOptions.get(prop);
        //console.log("options", options);
        //console.log("state.filterOptions", state.filterOptions);

        const filters = new Set<string>();
        state.filters.set(prop, filters);

        const onclick = (event: MouseEvent) => {
            event.preventDefault();
            event.stopImmediatePropagation();
            const elem = event.currentTarget as HTMLElement;    // gets the onclick element vs the element clicked on
            const option = (elem as any)["filter-option"];
            //console.log("click", option);
            if (event.ctrlKey) {
                if (filters.has(option)) {
                    filters.delete(option);
                    elem.classList.remove("filter-active");
                }
                else {
                    filters.add(option);                
                    elem.classList.add("filter-active");
                }

                if (filters.size == 0) {
                    filters.add("");
                    any.classList.add("filter-active");
                }
            }
            else {
                filters.clear();
                filter.filterContainer.querySelectorAll(".filter-active").forEach(elem => elem.classList.remove("filter-active"));
                filters.add(option);
                elem.classList.add("filter-active");
            }

            if (filter.urlParam)
                replaceUrl(filter.urlParam, filters);

            applyFilters(state);

            renderDataTemplate("item", state);

            // make sure the top of the item container is visible
            if (state.dataTemplate.container.getBoundingClientRect().top < 0)
                state.dataTemplate.container.scrollIntoView(true);

            return false;
        };

        // retrieve pre-selected options, if any
        const selected = new Set<string>();
        if (filter.urlParam) {
            const params = new URLSearchParams(window.location.search);
            for (const option of params.getAll(filter.urlParam)) {
                if (options?.has(option) || option === "")
                    selected.add(option);
            }
        }

        const any = filter.anyTemplate.cloneNode(true) as HTMLElement;
        any.onclick = onclick;
        (any as any)["filter-option"] = "";
        filter.filterContainer.appendChild(any);
        if (selected.has("") || selected.size == 0) {
            any.classList.add("filter-active");
            filters.add("");
        }

        if (options) {
            for (const option of [...options].sort()) {
                const clone = filter.optionTemplate.cloneNode(true) as HTMLElement;
                populateElement(clone, option, state);

                (clone as any)["filter-option"] = option;
                clone.onclick = onclick;

                if (selected.has(option)) {
                    clone.classList.add("filter-active");
                    filters.add(option);
                }

                filter.filterContainer.appendChild(clone);
                //console.log(option, clone);
            }
        }
    }
};

const matchesAllFilters = (item: any, state: CollectionState): boolean => {
    for (const [prop, filters] of state.filters) {
        const value = getValue(prop, item);
        if (filters.has(""))
            continue
        else if (Array.isArray(value)) {
            if (!value.some(val => filters?.has(val)))
                return false;
        }
        else if(!filters?.has(value))
                return false;
    }

    return true;
};

export const applyFilters = (state: CollectionState) => {
    state.filtered = [];

    if (!state.items)
        return;

    for (const item of state.items) {
        if (matchesAllFilters(item, state))
            state.filtered.push(item);
    }
};
