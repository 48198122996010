import { renderDataTemplate } from './templates';

export type Sorter = {
  param: string;
  default?: string;
  options: Map<string, string>;
  selected?: string;
  onChange?: (option: string, prop: string) => void;
};

export const findSorters = (): Map<string, Sorter> => {
  const sorters: Map<string, Sorter> = new Map;
  for (const elem of document.querySelectorAll("[sort-collection]")) {
      const collection = elem.getAttribute("sort-collection")?.trim() || '__default__';
      const urlParam = elem.getAttribute("sort-url-param")?.trim();
      const defaultValue = elem.getAttribute("sort-default")?.trim();

      elem.removeAttribute("sort-collection");
      elem.removeAttribute("sort-url-param");
      elem.removeAttribute("sort-default");

      if (!urlParam) continue;

      const sorter: Sorter = {
        param: urlParam,
        default: defaultValue,
        options: new Map(),
      };

      const container = elem as HTMLElement;
      for (const optionElem of container.querySelectorAll('[sort-value]')) {
        const value = optionElem.getAttribute('sort-value')?.trim();
        const prop = optionElem.getAttribute('sort-prop')?.trim();

        optionElem.removeAttribute('sort-value');
        optionElem.removeAttribute('sort-prop');

        if (value && prop) {
          sorter.options.set(value, prop);

          if (optionElem instanceof HTMLOptionElement === false) {
            optionElem.addEventListener('click', () => sorter.onChange?.(value, prop));
          }
        }
      }

      if (container instanceof HTMLSelectElement) {
        const query = new URLSearchParams(window.location.search);
        const urlOption = query.get(sorter.param);
        const option = query.get(sorter.param) || container.value || sorter.default;
        if (option && container.value !== option) container.value = option;
        sorter.selected = option;

        container.addEventListener('change', () => {
          const value = container.value;
          if (value) {
            const prop = sorter.options.get(value);
            if (prop && sorter.onChange) {
              sorter.onChange(value, prop);
            }
          }
        });
      }

      if (sorter.options.size) {
        sorters.set(collection, sorter);
      }
  }

  return sorters;
};
